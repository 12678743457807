#Group_10 {
    position: absolute;
    width: 85vw;
    height: 53px;
    top: 90px;
    overflow: visible;
    margin-left: 13vw;
    display: flex;
    align-items: center;
    /* border-radius: 10px; */

    /* justify-content: ; */
    /* border: 1px solid black; */
}

#angle-left_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: visible;
    cursor: pointer;
    margin-top: -15px;
}

#Group_8 {
    position: absolute;
    width: 154px;
    height: 53px;

    overflow: visible;
    /* border: 1px solid black; */
    margin-left: 4vw;
    align-items: center;
    border-radius: 6px;
    box-shadow: 5px 8px 4px rgba(17, 15, 8, 0.1);

    margin-top: -27px;

    cursor: pointer;

}

#office-building {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    top: 10px;
    overflow: visible;
}

#Companies {
    margin-left: 35px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
}

#Group_4 {
    position: absolute;
    width: 119px;
    height: 30px;
    left: 18px;
    top: 12px;
    overflow: visible;
}

#Group_7 {
    position: absolute;
    width: 154px;
    height: 53px;
    overflow: visible;
    margin-left: 15vw;
    align-items: center;
    border-radius: 6px;
    box-shadow: 5px 8px 4px rgba(17, 15, 8, 0.1);

    margin-top: -27px;
    cursor: pointer;


}

#Group_3 {
    position: absolute;
    width: 104px;
    height: 30px;
    left: 25px;
    top: 12px;
    overflow: visible;
}

#Packages {
    margin-left: 35px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
}

#answer {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    top: 10px;
    overflow: visible;
}

#Group_6 {
    position: absolute;
    width: 154px;
    height: 53px;
    overflow: hidden;
    margin-left: 26vw;
    align-items: center;
    border-radius: 6px;
    box-shadow: 5px 8px 4px rgba(17, 15, 8, 0.1);

    margin-top: -27px;
    cursor: pointer;
}

#Group_1 {
    position: absolute;
    width: 104px;
    height: 30px;
    left: 25px;
    top: 12px;
    overflow: visible;
}

#FAQs {
    margin-left: 35px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
}

#question-mark {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    top: 10px;
    overflow: visible;
}

#Group_5 {
    position: absolute;
    width: 154px;
    height: 53px;
    /* left: 0px;
    top: 0px; */
    overflow: visible;
    /* border: 1px solid black; */
    margin-left: 37vw;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 5px 8px 4px rgba(17, 15, 8, 0.1);
    margin-top: -27px;

}

#Group_2 {
    position: absolute;
    width: 86px;
    height: 30px;
    left: 34px;
    top: 12px;
    overflow: visible;
}

#Tickets {
    margin-left: 35px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
}

#alert {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    top: 10px;
    overflow: visible;
}

#Group_16 {
    position: absolute;
    width: 37px;
    height: 57px;
    border: 2px solid rgb(51, 248, 1);
    cursor: pointer;
    right: 0px;
    border-radius: 7px;
    align-items: center;
    box-shadow: 5px 8px 4px rgba(0, 0, 2, 0.1)
}

#add {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 2px;
    top: 10px;
    overflow: hidden;
}