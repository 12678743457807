	.mediaViewInfo {
	    --web-view-name: login;
	    --web-view-id: login;
	    --web-scale-on-resize: true;
	    --web-center-horizontally: true;
	    --web-enable-deep-linking: true;
	}

	:root {
	    --web-view-ids: login;
	}

	* {
	    margin: 0;
	    padding: 0;
	    box-sizing: border-box;
	    border: none;
	}

	#login {
	    position: absolute;
	    width: 100vw;
	    height: 100vh;
	    background-color: rgba(255, 255, 255, 1);
	    overflow: hidden;
	    --web-view-name: login;
	    --web-view-id: login;
	    --web-scale-on-resize: true;
	    --web-center-horizontally: true;
	    --web-enable-deep-linking: true;
	    display: flex;
	    justify-content: space-between;
	}

	#Group_44 {
	    position: absolute;
	    width: 50vw;
	    height: 95vh;

	    overflow: hidden;

	}

	#n_625451_2453278 {
	    position: relative;
	    width: 930px;
	    height: 923px;
	    left: 0px;
	    top: 0px;
	    overflow: hidden;
	}

	#Rectangle_34 {
	    fill: rgba(255, 255, 255, 1);
	}

	.Rectangle_34 {
	    position: absolute;
	    overflow: visible;
	    width: 450px;
	    height: 45px;
	    left: 96px;
	    top: 40px;
	}

	#Group_41 {
	    position: absolute;
	    width: 102px;
	    height: 102px;
	    left: 34px;
	    top: 11px;
	    overflow: visible;
	}

	#Ellipse_2 {
	    fill: rgba(255, 255, 255, 1);
	}

	.Ellipse_2 {
	    position: absolute;
	    overflow: visible;
	    width: 102px;
	    height: 102px;
	    left: 0px;
	    top: 0px;
	}

	#logo_si {
	    position: absolute;
	    width: 95px;
	    height: 95px;
	    left: 4px;
	    top: 4px;
	    overflow: visible;
	}

	#Client_Technical_Support {
	    left: 144px;
	    top: 40px;
	    position: absolute;
	    overflow: visible;
	    width: 344px;
	    white-space: nowrap;
	    text-align: left;
	    font-family: Segoe UI;
	    font-style: normal;
	    font-weight: bold;
	    font-size: 30px;
	    color: rgba(226, 27, 27, 1);
	}

	#Group_43 {
	    position: absolute;
	    width: 50vw;
	    height: 95vh;
	    overflow: hidden;
	    right: 1px;
	}

	#n_19225_OBDKVJ0 {
	    position: absolute;
	    width: 930px;
	    height: 923px;
	    left: 0px;
	    top: 0px;
	    overflow: hidden;
	}

	#btn {
	    position: absolute;
	    width: 305px;
	    height: 45px;
	    left: 199px;
	    top: 346px;
	    overflow: visible;
	    --web-animation: fadein undefineds undefined;
	    --web-action-type: page;
	    --web-action-target: Home.html;
	    cursor: pointer;
	    border-radius: 5px;
        background-color: green;
	}

	#Rectangle_3_r {
	    fill: url(#Rectangle_3_r);
	}

	.Rectangle_3_r {
	    position: absolute;
	    overflow: visible;
	    width: 305px;
	    height: 45px;
	    left: 0px;
	    top: 0px;
	}

	#Sign_in {
	    left: 126px;
	    top: 12px;
	    position: absolute;
	    overflow: visible;
	    width: 55px;
	    white-space: nowrap;
	    text-align: left;
	    font-family: Berlin Sans FB;
	    font-style: normal;
	    font-weight: normal;
	    font-size: 20px;
	    color: rgba(255, 255, 255, 1);
	}

	#username {
	    fill: rgba(254, 254, 254, 1);
	}

	.username {
	    position: absolute;
	    overflow: visible;
	    width: 305px;
	    height: 46px;
	    left: 199px;
	    top: 202px;
	    border-radius: 5px;
	    border: solid 2px;
	    padding: 5px;
	}

	#password {
	    fill: rgba(255, 255, 255, 1);
	}

	.password {
	    position: absolute;
	    overflow: visible;
	    width: 305px;
	    height: 46px;
	    left: 199px;
	    top: 262px;
	    border-radius: 5px;
	    border: solid 2px;
	    padding: 5px;
	}

	.inputs {
	    position: absolute;
	    left: 100px;
	    top: 50px;
        align-items: center;
        display: flex;
        justify-content: center;
	}