/* .modal-1 {
    position: absolute,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
} */
.modal-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400; */
    background-color: white;
    border-radius: 8px;
}

.add-Users-1 {
    height: auto;
    width: 25vw;
    background-color: white;
    align-items: center;
    border-radius: 8px;

}

.add-Users-header-1 {
    width: 25vw;
    height: 10vh;
    background-color: rgb(120, 253, 138);
    display: flex;
    align-items: center;

}

#add-Users-name-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(0, 0, 0, 1);
    margin-left: 9vw;
}

.close-btn-Users-1 {
    margin-left: 7vw;
    background-color: rgb(120, 253, 138);
    cursor: pointer;
}

.inputs-add-Users-group {
    align-items: center;
    width: 25vw;
    height: auto;
    overflow: scroll;
}

#add-Users-close-img-1 {
    width: 30px;
    height: 30px;
    cursor: pointer;

}

.Users-names-1 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Users-name-header-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.Users-name-input-1 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Users-names-2 {
    padding: 7px;
    margin-left: 20px;
}

#Users-name-header-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.Users-name-input-2 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Users-names-4 {
    padding: 7px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;

}

#Users-name-header-4 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.Users-name-input-4 {
    width: 10vw;
    height: 8vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;
    margin-left: 10px;
}

.Users-names-5 {
    padding: 7px;
    margin-left: 20px;
}

#Users-name-header-5 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.Users-name-input-5 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Users-names-7 {
    padding: 7px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#Users-name-header-7 {
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 50px;

}

.Users-name-input-7 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

#add-Users-close-img-2 {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 10px;

}

.add-btn-Users-1 {
    padding: 15px;
    background-color: rgb(81, 241, 81);
    align-items: center;
    border-radius: 8px;
    margin-left: 10vw;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
    font-size: 19px;
    cursor: pointer;
    margin-top: 10px;


}