/* .modal-1 {
    position: absolute,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
} */
.modal-add-company-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400; */
    background-color: white;
    border-radius: 8px;
}

.add-Company-1 {
    height: 85vh;
    width: 25vw;
    background-color: white;
    align-items: center;
    border-radius: 8px;

}

.add-Company-header-1 {
    width: 25vw;
    height: 7vh;
    background-color: rgb(120, 253, 138);
    /* border: 1px solid black; */
}

.add-Company-name-11 {
    margin-top: -40px;
    margin-left: 9vw;
    border-radius: 55px;
    display: flex;
    justify-content: center;
    width: 110px;
    height: 110px;
    background-color: rgb(255, 255, 255);
    align-items: center;
    border: 1px solid rgb(201, 197, 197);

}

#add-Company-name-1 {
    width: 80px;
    height: 80px;
    background-color: rgb(173, 171, 171);
    align-items: center;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    border: 1px solid rgb(212, 209, 209);

}

#add-Company-profile-img-1 {
    width: 75px;
    height: 75px;
}

.add-Company-close-btn-1 {
    margin-left: 22vw;
    background-color: rgb(120, 253, 138);
    cursor: pointer;
    margin-top: 20px;
}

.swicth-active-1 {
    /* border: 1px solid black; */
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 46px;
}

.inputs-add-Company-group {
    align-items: center;
    width: 25vw;
    height: 61vh;
    overflow: scroll;
}

#add-Company-close-img-1 {
    width: 30px;
    height: 30px;

}

.Company-names-1 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Company-name-header-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-1 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Company-names-15 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Company-name-header-15 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-15 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Company-names-2 {
    padding: 7px;
    margin-left: 20px;
}

#Company-name-header-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-2 {
    width: 22vw;
    height: 10vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.Company-names-3 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-3 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-3 {
    width: 17vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.add-compnay-input-uplod-showdoc-group-3 {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

#add-Company-upload-img-3 {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;

}

#add-Company-show-doc-img-3 {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;

}
.Company-names-16 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Company-name-header-16 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-16 {
    width: 17vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.add-compnay-input-uplod-showdoc-group-1 {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

#add-Company-upload-img-1 {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;
    
}

#add-Company-show-doc-img-1 {
    width: 30px;
    height: 30px;
    margin-left: 15px;
    cursor: pointer;

}

.Company-names-4 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-4 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-4 {
    width: 22vw;
    height: 10vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.add-company-state-country-group-1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Company-names-5 {
    padding: 7px;
}

#Company-name-header-5 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-5 {
    width: 10.6vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Company-names-6 {
    padding: 7px;

}

#Company-name-header-6 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-6 {
    width: 10.6vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.add-company-state-country-group-2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Company-names-7 {
    padding: 7px;
}

#Company-name-header-7 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-7 {
    width: 10.6vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Company-names-8 {
    padding: 7px;

}

#Company-name-header-8 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-8 {
    width: 10.6vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.Company-names-9 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-9 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-9 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}


.Company-names-10 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-10 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-10 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}


.Company-names-11 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-11 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-11 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.Company-names-12 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-12 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-12 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.Company-names-13 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-13 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-13 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.Company-names-14 {
    padding: 7px;
    margin-left: 20px;

}

#Company-name-header-14 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-company-name-input-14 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;

}

.add-btn-Company-1 {
    padding: 15px;
    background-color: rgb(81, 241, 81);
    align-items: center;
    border-radius: 8px;
    margin-left: 10vw;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
    font-size: 19px;
    cursor: pointer;
    margin-top: 5px;

}