.package-text{
    align-items: center;
    width: 80vw;
    height: 12vh;
    /* border: 1px solid black; */
    margin-left: 7vw;
    margin-top: 170px;
    display: flex;
}
.package-textarea-1{
    width: 17vw;
    border: 1px solid black;
    height:12vh;
    border-radius: 6px;
    padding: 10px;
}
.package-textarea-2{
    width: 17vw;
    border: 1px solid black;
    height:12vh;
    border-radius: 6px;
    padding: 10px;
    margin-left: 20px;

}
.package-textarea-3{
    width: 17vw;
    border: 1px solid black;
    height:12vh;
    border-radius: 6px;
    padding: 10px;
    margin-left: 20px;

}
.package-textarea-4{
    width: 17vw;
    border: 1px solid black;
    height:12vh;
    border-radius: 6px;
    padding: 10px;
    margin-left: 20px;

}
.save-texts-1{
    border: 1px solid green;
    width: 4vw;
    height: 12vh;
    margin-left: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background-color: rgb(184, 252, 188);

}
#save-texts-img{
    width: 34px;
    height: 40px;
}