#Group_32 {
    position: absolute;
    width: 189px;
    height: 84vh;
    left: 34px;
    top: 90px;
    overflow: visible;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    align-items: center;
    padding: 5px;
    box-shadow: 2px 4px 8px rgb(88, 86, 86);
    
}

.scrool-cards {
    overflow: auto;
    display: block;
    height: 78vh;
    width: 183px;
}

.company-group {
    position: relative;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    width: 180px;;
    height: 50px;
    margin-top: 7px;
    justify-content: space-between;
    /* background-color:rgba(255, 87, 87, 1); */
    cursor: pointer;
    overflow: hidden;
}

#bizibees_ci2x {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: visible;
    border-radius: 19px;
    margin-left: 5px;

}

#Bizi_Bees {
    position: absolute;
    overflow: hidden;
    width: 7vw;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
    margin-left: 40px;
}

#Group_29 {
    position: absolute;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    width: 180px;
    height: 40px;
    margin-top: 7px;
    justify-content: space-between;
    background-color: rgba(97, 233, 142, 1);;
    cursor: pointer;
    overflow: hidden;

}

#Add_New {
    position: absolute;
    overflow: hidden;
    width: 7vw;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
    margin-left: 40px;
}

#add_cy {
    position: absolute;
    width: 30px;
    height: 30px;
    overflow: visible;
    border-radius: 19px;
    margin-left: 5px;
}
