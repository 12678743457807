#ticket {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(243, 243, 243, 1);
    overflow: hidden;
}

.ticket-main-1 {
    width: 84vw;
    height: 23vh;
    margin-left: 15vw;
    margin-top: 20vh;
    display: flex;
    align-items: center;
}

.ticket-priority-1 {
    width: 20vw;
    height: 23vh;
    background-color: white;
    border-radius: 10px;
    align-items: center;
    display: block;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    box-shadow: 2px 4px 8px rgb(88, 86, 86);

}

.all-ticket-1 {
    font-size: 17px;
    font-family: Poppins;
    font-style: normal;
    text-align: center;
}

#graph-1 {
    width: 160px;
    height: 160px;
    margin-top: 10px;
}


.ticket-priority-2 {
    width: 20vw;
    height: 23vh;
    background-color: white;
    border-radius: 10px;
    align-items: center;
    display: block;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    margin-left: 40px;
    box-shadow: 2px 4px 8px rgb(88, 86, 86);

}

.all-ticket-2 {
    font-size: 17px;
    font-family: Poppins;
    font-style: normal;
    text-align: center;
}

#graph-2 {
    width: 160px;
    height: 160px;
    margin-top: 10px;
}

.ticket-priority-3 {
    width: 40vw;
    height: 23vh;
    background-color: white;
    border-radius: 10px;
    margin-left: 40px;
    padding: 10px;
    box-shadow: 2px 4px 8px rgb(88, 86, 86);


}

.all-ticket-3 {
    font-size: 17px;
    font-family: Poppins;
    font-style: normal;
}

.open-ticket-1 {
    font-size: 17px;
    font-family: Poppins;
    font-style: normal;
    margin-top: 5px;
    color: rgb(88, 92, 87);


}

.open-bar-1 {
    width: 38vw;
    height: 15px;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 7px;
    background: linear-gradient(to right,
            rgb(0, 0, 255) 0%,
            /* 50% Blue */
            rgb(0, 0, 255) 70%,
            /* 50% Blue */
            rgb(169, 169, 169) 70%,
            /* 50% Grey */
            rgb(169, 169, 169) 100%
            /* 50% Grey */
        );
}

.search-1 {
    width: 84vw;
    height: 35px;
    border: 1px solid green;
    margin-left: 15vw;
    margin-top: 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 4px rgb(196, 192, 192);

}

.inpute-search-1 {
    width: 81.5vw;
    height: 33px;
    padding: 10px;
    border-radius: 8px;
    
}

#search-img-1 {
    width: 25px;
    height: 25px;
}

.ticket-middle-1 {
    width: 84vw;
    height: 10vh;
    margin-left: 15vw;
    margin-top: 15px;
    align-items: center;
    display: flex;
}

.ticket-middel-btn {
    width: 65px;
    height: 10vh;
    border: 2px solid green;
    align-items: center;
    display: block;
    border-radius: 6px;
    background-color: rgb(183, 245, 183);
    box-shadow: 2px 4px 8px rgb(88, 86, 86);

}

#ticket-middel-btn-img {
    width: 30px;
    height: 30px;
}

.ticket-high-priority-1 {
    width: 17vw;
    height: 10vh;
    align-items: center;
    display: flex;
    border-radius: 6px;
    margin-left: 2vw;
    background-color: rgb(252, 255, 252);
    padding: 5px;
    box-shadow: 4px 8px 16px rgba(255, 0, 0, 0.7);

}

.ticket-high-priority-box-1 {
    width: 5vw;
    height: 8vh;
    background-color: rgba(255, 87, 87, 1);
    border-radius: 7px;


}

.ticket-high-priority-name-count-1 {
    margin-left: 20px;
}

.ticket-high-priority-name-1 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-count-1 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-2 {
    width: 17vw;
    height: 10vh;
    align-items: center;
    display: flex;
    border-radius: 6px;
    margin-left: 2vw;
    background-color: rgb(252, 255, 252);
    padding: 5px;
    box-shadow: 4px 8px 16px rgba(166, 206, 227, 1);


}

.ticket-high-priority-box-2 {
    width: 5vw;
    height: 8vh;
    background-color: rgba(166, 206, 227, 1);
    border-radius: 7px;


}

.ticket-high-priority-name-count-2 {
    margin-left: 20px;
}

.ticket-high-priority-name-2 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-count-2 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-3 {
    width: 17vw;
    height: 10vh;
    align-items: center;
    display: flex;
    border-radius: 6px;
    margin-left: 2vw;
    background-color: rgb(252, 255, 252);
    padding: 5px;
    box-shadow: 4px 8px 16px rgba(255, 173, 78, 1);


}

.ticket-high-priority-box-3 {
    width: 5vw;
    height: 8vh;
    background-color: rgba(255, 173, 78, 1);
    border-radius: 7px;


}

.ticket-high-priority-name-count-3 {
    margin-left: 20px;
}

.ticket-high-priority-name-3 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-count-3 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-4 {
    width: 17vw;
    height: 10vh;
    align-items: center;
    display: flex;
    border-radius: 6px;
    margin-left: 2vw;
    background-color: rgb(252, 255, 252);
    padding: 5px;
    box-shadow: 4px 8px 16px rgba(32, 191, 85, 1);

}

.ticket-high-priority-box-4 {
    width: 5vw;
    height: 8vh;
    background-color: rgba(32, 191, 85, 1);
    border-radius: 7px;


}

.ticket-high-priority-name-count-4 {
    margin-left: 20px;
}

.ticket-high-priority-name-4 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-high-priority-count-4 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    color: rgb(15, 17, 14);
}

.ticket-bottom-1 {
    width: 84vw;
    height: 32vh;
    margin-left: 15vw;
    background-color: white;
    border-radius: 7px;
    margin-top: 20px;
    box-shadow: 2px 4px 8px rgb(88, 86, 86);
}