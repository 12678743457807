	.mediaViewInfo {
		--web-view-name: Index;
		--web-view-id: Index;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: Index;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Index {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-color: rgba(243,243,243,1);
		overflow: hidden;
		--web-view-name: Index;
		--web-view-id: Index;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}
	.company-subscription{
		/* border: 1px solid black; */
		margin-left: 13vw;
		margin-top: 18vh;
		width: 85vw;
		height: 50vh;
		display: inline-flex;
		overflow: hidden;

	}

	
