	.mediaViewInfo {
		--web-view-name: Home;
		--web-view-id: Home;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}

	:root {
		--web-view-ids: Home;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}

	#Home {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-color: rgba(255, 255, 255, 1);
		overflow: hidden;
		--web-view-name: Home;
		--web-view-id: Home;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}

	.btn-software {
		margin-top: 12vh;
		margin-left: 4vw;
		/* border: 1px solid black; */
		display: flex;
		/* justify-content: space-evenly; */
	}

	.add-btn {

		width: 5vw;
		height: 20vh;
		background-color: rgba(205, 227, 254, 1);
		border-radius: 10px;
		border: 1px solid rgba(90, 167, 255, 1);
		cursor: pointer;
	}

	.img-btn {
		width: 50px;
		height: 50px;
	}

	.software-cards {
		margin-left: 20px;
		display: inline-block;
		overflow: scroll;
		width: 87vw;
		height: 86vh;
		padding: 10px;
		align-items: center;
		margin-top: -12px;
	}