	.mediaViewInfo {
		--web-view-name: packages;
		--web-view-id: packages;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
	}

	:root {
		--web-view-ids: packages;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}

	#packages {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-color: rgba(243, 243, 243, 1);
		overflow: hidden;
		--web-view-name: packages;
		--web-view-id: packages;
		--web-scale-on-resize: true;
		--web-center-horizontally: true;
		--web-enable-deep-linking: true;
		align-items: center;
	}

	.addfeature-packages {
		/* border: 1px solid black; */
		width: 20vw;
		height: 5vh;
		margin-left: 40vw;
		margin-top: 10px;
		align-items: center;
		display: flex;
	}

	#Packages_ev {
		position: absolute;
		overflow: visible;
		width: 123px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(46, 136, 77, 1);
	}

	#Rectangle_54 {
		fill: rgba(255, 255, 255, 1);
		stroke: rgba(45, 167, 86, 1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}

	.Rectangle_54 {
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 45px;
		margin-left: 130px;
		border: 1px solid #2DA756;
		border-radius: 7px;
		padding: 5px;
	}


	.packages-types {
		/* border: 1px solid black; */
		width: 80vw;
		height: 5vh;
		margin-left: 7vw;
		margin-top: 6px;
		align-items: center;
		display: flex;
		/* justify-content: space-; */
	}

	#Group_74 {
		position: absolute;
		width: 134px;
		height: 42px;
		overflow: visible;
		--web-animation: fadein undefineds undefined;
		--web-action-type: page;
		--web-action-target: add_feature.html;
		cursor: pointer;
		background-color: #7bf1a2;
		border-radius: 15px;
		border: 1px solid black;
		align-items: center;
	}


	#Add_Feature {
		left: 21px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0, 0, 0, 1);
	}

	.packages-group-12 {
		/* border: 1px solid black; */
		display: flex;
		align-items: center;
		margin-left: 11vw;

	}

	.dlt-package-1 {
		position: relative;
		/* border: 1px solid black; */
		display: flex;
		align-items: center;
		margin-left: 40px;
		width: 10vw;
		justify-content: center;

	}

	#Package_1 {
		overflow: hidden;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(46, 136, 77, 1);
		margin-left: 20px;

	}

	#trash_1_gc {
		width: 21px;
		height: 21px;
	}

	#add-package-grp {
		position: absolute;
		width: 134px;
		height: 42px;
		overflow: visible;
		--web-animation: fadein undefineds undefined;
		--web-action-type: page;
		--web-action-target: add_feature.html;
		cursor: pointer;
		background-color: #7bf1a2;
		border-radius: 15px;
		border: 1px solid black;
		align-items: center;
		margin-left: 81vw;
	}

	#Add_package-1 {
		left: 21px;
		top: 9px;
		position: absolute;
		overflow: visible;
		width: 82px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(0, 0, 0, 1);
	}

	.scroll-packages-1 {
		width: 90vw;
		/* border: 1px solid black; */
		height: 45vh;
		overflow: scroll;
		margin-left: 5vw;
		align-items: center;
		overflow-x: hidden;
		margin-top: 10px;

	}

	.package-selection {
		/* border: 1px solid black; */
		height: 50px;
		width: 90vw;
		margin-top: 15px;
		align-items: center;
		display: flex;
	}

	#question-1 {
		width: 30px;
		height: 30px;
	}

	.features-mark-1 {
		border: 1px solid rgb(5, 1, 1);
		height: 50px;
		width: auto;
		margin-left: 10px;
		align-items: center;
		display: flex;
		border-radius: 8px;
		background-color: white;
		position: relative;

	}


	.dlt-feature-1 {
		display: flex;
		align-items: center;
		width: 140px;
		margin-left: 18px;
	}

	#feature-1 {
		position: absolute;
		overflow: visible;
		width: 100px;
		white-space: nowrap;
		text-align: center;
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(46, 136, 77, 1);
		margin-left: 27px;
	}

	#dlt_1_gc {
		position: absolute;
		width: 21px;
		height: 21px;
		overflow: visible;
	}

	.check-features-group {
		display: flex;
		align-items: center;
		/* border: 1px solid black; */
	}

	.check-feature-1 {
		width: 10vw;
		height: 5vh;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 55px;

	}

	#check-1 {
		width: 30px;
		height: 30px;
	}

	.check-feature-2 {
		width: 10vw;
		height: 5vh;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 115px;
	}

	#check-2 {
		width: 30px;
		height: 30px;
	}

	.check-feature-3 {
		width: 10vw;
		height: 5vh;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 110px;

	}

	#check-3 {
		width: 30px;
		height: 30px;
	}

	.check-feature-4 {
		width: 10vw;
		height: 5vh;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 110px;

	}

	#check-4 {
		width: 30px;
		height: 30px;
	}

	.check-feature-5 {
		width: 10vw;
		height: 5vh;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: 110px;

	}

	#check-5 {
		width: 30px;
		height: 30px;
	}

	#arrow-down-1 {
		width: 30px;
		height: 30px;
		margin-left: 30px;
	}

	#arrow-up-1 {
		width: 30px;
		height: 30px;
		margin-left: 20px;

	}

	.cost-1 {
		width: 80vw;
		height: 50px;
		border: 1px solid black;
		margin-left: 9vw;
		background-color: white;
		border-radius: 7px;
		display: flex;
		/* justify-content: space-between; */
		align-items: center;
		margin-top: 20px;
	}

	.cost-name-1 {
		width: 8vw;
		font-size: 20px;
		margin-left: 10px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
	}

	#cost-n_01 {
		width: 12vw;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
		margin-left: 23px;
		text-align: center;
	}

	#cost-n_02 {
		width: 11vw;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
		margin-left: 6vw;
		text-align: center;

	}

	#cost-n_03 {
		width: 12vw;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
		margin-left: 5vw;
		text-align: center;

	}

	#cost-n_04 {
		width: 10vw;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
		margin-left: 6vw;
		text-align: center;

	}

	#cost-n_05 {
		width: 11vw;
		font-size: 20px;
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 17px;
		color: rgba(0, 0, 0, 1);
		margin-left: 6vw;
		text-align: center;

	}