
#footer_app_pc_candi_r {
    position: absolute;
    width: 100vw;
    height: 5vh;
    left: 0;
    top: 95%;
    overflow: visible;
    background-color: rgba(32, 191, 85, 1); /* Setting background color to match the SVG rect */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.footer_vacan_r {
    display: none; /* Hiding the SVG as its purpose is served by the background color */
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 110%;
    padding: 0 20px;
    box-sizing: border-box;
}

.left-section {
    flex: 1;
    display: flex;
    align-items: center;
    /* border: 1px solid white; */
}

.center-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.right-section {
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* border: 1px solid white; */

}

#All_rights_reserved__2022_Oper_r {
    font-family: Segoe UI, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    text-align: left;
}

#linkedin_vacan_r,
#whatapp_btn_img_vacan_r {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 50px;
}

#term_and_pol_grp_r {
    display: flex;
    align-items: center;
}

#Terms_and_Conditions_lbl_vac_sa,
#Private_Policy_lbl_vac_sb {
    font-family: Segoe UI, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    margin-left: 20px;
    white-space: nowrap;
}

.Line_2_vacan_sc {
    fill: transparent;
    stroke: rgba(255, 255, 255, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    height: 24px;
    margin-left: 20px;
}
