#header {
    position: absolute;
    width: 100vw;
    height: 8vh;
    overflow: visible;
    /* filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161)); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 8px 4px rgba(0, 0, 2, 0.1)
}

.header-main {
    display: flex;
    /* border: 1px solid black; */
    align-items: center;
}

#logo {
    width: 41px;
    height: 41px;
    overflow: hidden;
    margin-left: 6vw;

}

#Support-Portal {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    margin-left: 20px;
    font-weight: bold;
}

.name-logout {
    display: flex;
    margin-right: 10px;
}

#user {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 30px;

}

#setup-1 {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 30px;
}

#logout {
    margin-right: 30px;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    overflow: visible;
    cursor: pointer;
}

#Mohammed_Affan {
    margin-right: 10px;
    overflow: hidden;
    width: 15vw;
    white-space: nowrap;
    text-align: right;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);

}
.user-btn-1{
    background-color: rgb(243, 239, 239);
    cursor: pointer;
}