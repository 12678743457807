.modal-1-Package {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400; */
    background-color: white;
    border-radius: 8px;
}

.add-Package-1 {
    height: auto;
    width: 25vw;
    background-color: white;
    align-items: center;
    border-radius: 8px;

}

.add-Package-header-1 {
    width: 25vw;
    height: 10vh;
    background-color: rgb(120, 253, 138);
    display: flex;
    align-items: center;

}

.swicth-active-1-Package {
    margin-left: 10vw;
}

#add-Package-name-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(0, 0, 0, 1);
    margin-left: 8vw;
}

.close-btn-1 {
    margin-left: 7vw;
    background-color: rgb(120, 253, 138);
    cursor: pointer;
}

.inputs-add-Package-group {
    align-items: center;
    width: 25vw;
    height: auto;
    overflow: scroll;
}

#add-Package-close-img-1 {
    width: 30px;
    height: 30px;

}

.Package-names-1 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Package-name-header-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-Package-name-input-1 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Package-names-2 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Package-name-header-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-Package-name-input-2 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Package-names-3 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Package-name-header-3 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-Package-name-input-3 {
    width: 22vw;
    height: 10vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.Package-Amount-Currency-group {
    display: flex;
    align-items: center;
}

.Package-names-4 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Package-name-header-4 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-Package-name-input-4 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}

.add-month-year-Package-1 {
    display: flex;
}

.add-Package-name-input-5 {
    width: 5vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;
    margin: 5px;

}

.add-btn-Package-1 {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 10vw;
    align-items: center;
    border-radius: 9px;
    background-color: rgb(94, 240, 94);
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 10px;
}