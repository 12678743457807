/* .modal-1 {
    position: absolute,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
} */
.modal-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400; */
    background-color: white;
    border-radius: 8px;
}

.add-Person-1 {
    height: 85vh;
    width: 25vw;
    background-color: white;
    align-items: center;
    border-radius: 8px;

}

.add-Person-header-1 {
    width: 25vw;
    height: 10vh;
    background-color: rgb(120, 253, 138);
    display: flex;
    align-items: center;

}
.swicth-active-1-Person{
    margin-left: 10vw;
}
#add-Person-name-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(0, 0, 0, 1);
    margin-left: 8vw;
}

.close-btn-1 {
    margin-left: 7vw;
    background-color: rgb(120, 253, 138);
    cursor: pointer;
}

.inputs-add-Person-group {
    align-items: center;
    width: 25vw;
    height: 64vh;
    overflow: scroll;
}

#add-Person-close-img-1 {
    width: 30px;
    height: 30px;

}

.Person-names-1 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-1 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-2 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-2 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-3 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-3 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-3 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.dob-gender-group{
    display: flex;
    align-items: center;
}
.Person-names-4 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-4 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-4 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-5 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-5 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-5 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-6 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-6 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-6 {
    width: 22vw;
    height: 10vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding: 10px;

}
.country-state-group{
    display: flex;
    align-items: center;
}
.Person-names-7 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-7 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-7 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-8 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-8 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-8 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-9 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-9 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-9 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-10 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-10 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-10 {
    width: 10vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-11 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-11 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-11 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-12 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-12 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-12 {
    width: 22vw;
    height: 4vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding-left: 10px;

}
.Person-names-13 {
    padding: 7px;
    margin-left: 20px;
    margin-top: 10px;

}

#Person-name-header-13{
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    margin-left: 4px;

}

.add-person-name-input-13 {
    width: 22vw;
    height: 10vh;
    border: 1.8px solid rgb(9, 166, 228);
    border-radius: 7px;
    padding: 10px;

}
.add-btn-Person-1{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 10vw;
    align-items: center;
    border-radius: 9px;
    background-color: rgb(94, 240, 94);
    font-size: 17px;
}