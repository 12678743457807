#Group_26 {
    position: absolute;
    width: 29vw;
    height: 50vh;
    /* border: 1px solid rgba(32, 43, 191, 1); */
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgb(117, 117, 117);

}

#Group_23 {
    position: absolute;
    width: 28vw;
    height: 50vh;
    left: 20px;
    top: 5px;
    overflow: hidden;
}

#Company_Name {
    position: absolute;
    overflow: hidden;
    width: 158px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
    margin-top: 5px;

}

.Rectangle_9 {
    position: absolute;
    overflow: visible;
    width: 15vw;
    height: 38px;
    margin-left: 20px;
    left: 153px;
    top: 3px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#Database {
    left: 0px;
    top: 60px;
    position: absolute;
    overflow: hidden;
    width: 163px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Rectangle_12 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_12 {
    position: absolute;
    overflow: visible;
    width: 15vw;
    height: 35px;
    left: 153px;
    top: 59px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
    margin-left: 20px;

}


#Connection_String {

    overflow: hidden;
    width: 190px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Rectangle_12_ck {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_12_ck {
    position: absolute;
    overflow: visible;
    width: 19vw;
    height: 35px;
    left: 3px;
    top: 26px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

#Group_20 {
    position: absolute;
    width: 37px;
    height: 35px;
    left: 347px;
    top: 137px;
    overflow: visible;
    margin-left: 20px;
}

#Rectangle_13 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(28, 127, 255, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_13 {
    position: absolute;
    overflow: visible;
    width: 37px;
    height: 35px;
    left: 0px;
    top: 0px;
}

#checklist {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 6px;
    top: 5px;
    overflow: visible;
}

#Default_Password {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 195px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Rectangle_12_cp {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_12_cp {
    position: absolute;
    overflow: hidden;
    width: 17vw;
    height: 35px;
    left: 153px;
    top: 188px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}

.urls-group-1 {
    width: 27vw;
    height: 4vh;
    margin-top: 15px;
    align-items: center;
    display: flex;

}

#url-text-1 {
    overflow: hidden;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);

}

.url-input-1 {
    width: 20vw;
    height: 3.6vh;
    border: 2px solid lightblue;
    border-radius: 13px;
    padding: 10px;
}

.urls-group-2 {
    width: 27vw;
    height: 4vh;
    /* border: 1px solid black; */
    margin-top: 5px;
    align-items: center;
    display: flex;

}

.group-default-text-input-1 {
    margin-top: 20px;
}

.password-encription-text-group {
    width: 26vw;
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.default-group-encrypt-inputs-1 {
    width: 26vw;
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.default-input--passward-1 {
    width: 11vw;
    height: 3.6vh;
    border: 2px solid lightblue;
    border-radius: 8px;
    padding: 10px;
}

#worlwide-1 {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    cursor: pointer;
}

.connectionstring-group-1 {
    padding: 5px;
    align-items: center;
    margin-top: 100px;
}

.connection-string-input-1 {
    width: 25vw;
    height: 4vh;
    border: 2px solid lightblue;
    border-radius: 8px;
    padding: 10px;
}

.input-edit-group-1 {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

#checklist-1-edit {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}