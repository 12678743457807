#faq {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(243, 243, 243, 1);
    overflow: hidden;
}

.faq-header-1 {
    width: 40vw;
    height: 5vh;
    /* border: 1px solid black; */
    margin-left: 15vw;
    margin-top: 19vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-faq {
    background-color: rgb(137, 233, 137);
    font-size: 17px;
    /* padding: 12px; */
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 17px;
    border: 1px solid green;
    cursor: pointer;
}

.faq-name-1 {
    font-size: 25px;
    font-family: Poppins;
    font-style: normal;
    /* font-weight: bold; */
    color: rgb(25, 112, 3);
    margin-left: 6vw;
    text-align: center;

}

.need-help-1 {
    font-size: 20px;
    font-family: Poppins;
    font-style: normal;
    /* font-weight: bold; */
    color: rgb(45, 182, 11);
    margin-left: 6vw;
    text-align: center;
}
.question-need-help-1{
    width: 75vw;
    height: auto;
    padding: 10px;
    border: 1px solid rgb(49, 116, 22);
    margin-left: 15vw;
    margin-top: 19px;
    background-color: white;
   border-radius: 8px;
    
}
.need-help-main-1{
    width: 74vw;
    height: auto;
    padding: 2px;
    /* border: 1px solid purple; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.need-help-2{
    font-size: 17px;
    font-family: Poppins;
    font-style: normal;
    /* font-weight: bold; */
    color: rgb(45, 116, 27);
    text-align: center;
}
#need-help-arrow-down-1{
    width: 25px;
    height: 25px;
    margin-left: 10px;

}
#need-help-arrow-up-1{
    width: 25px;
    height: 25px;
    margin-left: 10px;

    
}
#need-help-eye-1{
    width: 25px;
    height: 25px;
    margin-left: 10px;

    
}
.need-help-answer-1{
    font-size: 15px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    color: rgb(45, 116, 27);
    text-align: left;
    margin-top: 12px;
}