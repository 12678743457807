#Group_31 {
    position: absolute;
    width: 85vw;
    height: 25vh;
    left: 13vw;
    overflow: visible;
    /* border: 1px solid rgba(32, 43, 191, 1); */
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 1px 4px 8px rgb(170, 168, 168);
    margin-top: 5px;

}

.search-bar {
    /* border: 1px solid black; */
    width: 42vw;
    height: 5vh;
    margin-left: 10px;
    margin-top: 15px;
    display: inline-flex;
    align-items: center;
}

#Users {
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#Rectangle_22 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_22 {
    overflow: hidden;
    width: 33vw;
    height: 35px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
    margin-left: 30px;
}

#search {
    width: 25px;
    height: 25px;
    overflow: visible;
    margin-left: 20px;

}

#Group_22 {
    width: 37px;
    height: 40px;
    overflow: hidden;
    border: 2px solid rgb(4, 247, 4);
    cursor: pointer;
    align-items: center;
    margin-left: 20px;
    border-radius: 7px;

}

#add_d {
    width: 25px;
    height: 25px;
    overflow: visible;
    margin-left: 4px;
    margin-top: 6px;
}


.users-1 {
    width: 43vw;
    height: 3vh;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    border: 1px solid grey;
    margin-top: 10px;
    margin-left: 5px;
}

#name-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#loginId-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#lastLogin-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#role-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#access-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#active-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Scroll-users-1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 43.5vw;
    height: 13.5vh;
    overflow-x: hidden;
    overflow-y: scroll;
    /* border: 1px solid grey; */
}

.users-2 {
    width: 43vw;
    height: 3vh;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    border: 1px solid grey;
    margin-top: 10px;
    margin-left: 5px;
}

#name-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#loginId-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#lastLogin-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#role-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#access-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#active-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

.role-access-cards {
    display: flex;
    /* border: 1px solid black; */
    margin-left: 45vw;
    height: 20vh;
    margin-top: -90px;


}

.role-card-1 {
    width: 210px;
    height: 218px;
    /* border: 1px solid black; */
    margin-left: 5vw;
    background-color: rgb(250, 246, 246);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border-radius: 8px;
}

.role-main {
    width: 210px;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

#roles-name {
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4, 4, 4, 1);
    margin-right: 50px;
}

#roles-plus {
    width: 20px;
    height: 20px;
    margin-right: 17px;
    cursor: pointer;

}

.role-main-1 {
    width: 210px;
    height: 28px;
    /* border: 1px solid black; */
    align-items: center;
    display: flex;
    /* justify-content: center; */
}

#roles-name-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
    margin-left: 10px;

}

#id-name-1 {
    width: 20px;
    height: 20px;
    margin-left: 110px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
}

.roles-scroll-1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 200px;
    height: 212px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* border: 1px solid black; */
    margin-left: 5px;

}

.role-main-2 {
    width: 198px;
    height: 28px;
    border: 2px solid rgb(58, 202, 106);
    align-items: center;
    display: flex;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(124, 126, 153, 0.37);
    border-radius: 5px;
    margin-bottom: 5px;

    /* justify-content: center; */
}

#roles-name-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
    margin-left: 9px;
    width: 156px;

}

#id-name-2 {
    /* width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1); */
    text-align: center;

}

.access-card-1 {
    width: 210px;
    height: 218px;
    /* border: 1px solid black; */
    margin-left: 5vw;
    background-color: rgb(250, 246, 246);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border-radius: 8px;
}

.access-main {
    width: 210px;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

#access-name {
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4, 4, 4, 1);
    margin-right: 50px;
}

#access-plus {
    width: 20px;
    height: 20px;
    margin-right: 17px;
    cursor: pointer;

}

.access-main-1 {
    width: 210px;
    height: 28px;
    /* border: 1px solid black; */
    align-items: center;
    display: flex;
    /* justify-content: center; */
}

#access-name-1 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
    margin-left: 19px;

}

#access-id-1 {
    width: 20px;
    height: 20px;
    margin-left: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
}

.access-scroll-1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 200px;
    height: 212px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* border: 1px solid black; */
    margin-left: 5px;

}

.access-main-2 {
    width: 198px;
    height: 28px;
    border: 2px solid rgb(58, 202, 106);
    align-items: center;
    display: flex;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(124, 126, 153, 0.37);
    border-radius: 5px;
    margin-bottom: 5px;

    /* justify-content: center; */
}

#access-name-2 {
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
    margin-left: 9px;
    width: 156px;

}

#access-id-2 {
    /* width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
    text-align: center; */
    /* border: 1px solid black; */
    text-align: center;
}