#Group_35 {
    position: relative;
    width: 202px;
    height: 184px;
    overflow: visible;
    cursor: pointer;
    align-items: center;
    margin: 10px;
    border: 1px solid black;
    display: inline-flex;
    box-shadow: 1px 2px 4px rgb(231, 231, 231);
    border-top-left-radius: 30px;
    text-align: center;
    vertical-align: middle;
}

#Screenshot_2023-09-28_150533_fy {
    position: relative;
    width: 190px;
    height: 120px;
    overflow: visible;
    margin-left: 5px;
    margin-top: -10px;
   

}

#Rectangle_31 {
    fill: rgba(32, 191, 85, 1);
    stroke: rgba(32, 191, 85, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_31 {
    position: absolute;
    overflow: visible;
    width: 202px;
    height: 34px;
    left: 0px;
    top: 150px;
}

#Operate_Live {
    left: 6px;
    top: 156px;
    position: absolute;
    overflow: visible;
    width: 192px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
}

#Group_37 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 169px;
    top: 3px;
    overflow: visible;
}

#Ellipse_1 {
    fill: rgba(255, 255, 255, 1);
}

.Ellipse_1 {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
}

#pen {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 5px;
    top: 5px;
    overflow: visible;
}