#Group_30 {
    position: absolute;
    width: 55vw;
    height: 50vh;
    overflow: hidden;
    /* border: 1px solid rgba(32, 43, 191, 1); */
    margin-left: 30vw;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgb(117, 117, 117);
}

#Group_25 {
    position: absolute;
    width: 55vw;
    height: 50vh;
    overflow: visible;

}

.sub-inv {
    border: 1px solid rgb(151, 175, 243);
    width: 53vw;
    height: 7vh;
    margin-top: 10px;
    margin-left: 15px;
    border-radius: 7px;

}

.sub-inv-1 {
    width: 53vw;
    height: 3vh;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

}

#Subscription-1 {
    overflow: hidden;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);

}

#Invoice-1 {
    overflow: hidden;
    width: 124px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);

}

.sub-inv-2 {
    width: 53vw;
    height: 3vh;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;

}

#Package-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#StartDate-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#EndDate-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Status-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Number-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Date-1 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Status-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}
#Scroll_Group_1 {
    mix-blend-mode: normal;
    position: absolute;
    width: 54vw;
    height:40.5vh;
    left: 9px;
    top: 82px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* border: 1px solid grey; */
}

.sub-inv-3 {
    width: 53vw;
    height: 3vh;
    border-radius: 7px;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    border: 1px solid rgb(119, 182, 241);
    margin-left: 5px;
}

#Package-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#StartDate-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#EndDate-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Status-22 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Number-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Date-2 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#Status-3 {
    overflow: hidden;
    /* width: 124px; */
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

